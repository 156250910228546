'use client';

import {
  createTheme,
  CSSVariablesResolver,
  DEFAULT_THEME,
  rem,
  TextInput,
  NumberInput,
  SegmentedControl,
  NavLink,
  MultiSelect,
  PasswordInput,
  Select,
  Tabs,
  Textarea,
  Drawer,
  TagsInput,
} from '@mantine/core';

export const theme = createTheme({
  components: {
    TextInput: TextInput.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        error: {
          marginLeft: 10,
          fontSize: 10,
        },
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        error: {
          marginLeft: 10,
          fontSize: 10,
        },
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        variant: 'filled',
      },
      styles: {
        error: {
          marginLeft: 10,
          fontSize: 10,
        },
      },
    }),
    MultiSelect: MultiSelect.extend({
      styles: {
        pill: {
          marginLeft: 10,
        },
        error: {
          marginLeft: 10,
          fontSize: 10,
        },
      },
    }),
    Select: Select.extend({
      styles: {
        error: {
          marginLeft: 10,
          fontSize: 10,
        },
      },
    }),
    PasswordInput: PasswordInput.extend({
      styles: {
        input: {
          marginLeft: 10,
        },
        error: {
          marginLeft: 10,
          fontSize: 10,
        },
      },
    }),
    NavLink: NavLink.extend({
      classNames: {
        root: 'nav-root',
        label: 'nav-label',
      },
      styles: {
        root: {
          color: 'black',
          backgroundColor: 'transparent',
        },
        label: {
          fontSize: 14,
        },
      },
    }),
    SegmentedControl: SegmentedControl.extend({
      styles: {
        root: {
          height: 88,
          backgroundColor: '#FFFFFF',
          width: '100%',
        },
        label: {
          marginTop: 15,
          fontSize: 15,
          fontWeight: 600,
          color: 'grey',
        },
        indicator: {
          height: 80,
          outlineStyle: 'dotted',
          outlineColor: 'grey',
        },
      },
    }),
    Tabs: Tabs.extend({
      styles: {
        tabLabel: {
          fontSize: 14,
          color: 'grey',
        },
      },
    }),
    TagsInput: TagsInput.extend({
      styles: {
        pill: {
          backgroundColor: '#EBE2FF',
          color: '#612CD3',
          borderRadius: 10,
        },
      },
    }),
  },
  colors: {
    ...DEFAULT_THEME.colors,
    brand: [
      '#F7F9FB',
      '#E1E6EB',
      '#C8D1D9',
      '#AFBCC8',
      '#97A7B7',
      '#7F92A6',
      '#687D95',
      '#526884',
      '#3C5373',
      '#283D63',
    ],
  },
  primaryColor: 'brand',
  defaultRadius: rem(10),
  other: {
    spaces: {
      p10: '0.625rem',
      p40: '2.5rem',
      p50: '3.125rem',
    },
  },
});

export const variableOverrides: CSSVariablesResolver = () => ({
  variables: {
    '--mantine-color-gray-light-hover': 'hotpink',
  },
  light: {
    '--mantine-color-gray-light-hover': '#F7F9FB',
  },
  dark: {
    '--mantine-color-gray-light-hover': 'hotpink',
  },
});
